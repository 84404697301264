@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EBEBEB;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

.MuiTextField-root input {
	padding-top: 12px;
	padding-bottom: 12px;
}

.MuiTextField-root fieldset {
	border: 0;
}

.modal {
	background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.modal .content {
	background-color:  white;
	width: 700px;
	margin: 160px auto 0 auto;
	padding: 20px 40px;
}

@font-face {
  font-family: 'icomoon';
  src:  url(/static/media/icomoon.1e25ee84.eot);
  src:  url(/static/media/icomoon.1e25ee84.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.e870e0e6.ttf) format('truetype'),
    url(/static/media/icomoon.1dea5c01.woff) format('woff'),
    url(/static/media/icomoon.8ef8c34d.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right:before {
  content: "\e900";
}
.icon-arrow-up:before {
  content: "\e901";
}
.icon-check:before {
  content: "\e902";
}
.icon-calendar:before {
  content: "\e903";
}
.icon-edit:before {
  content: "\e904";
}
.icon-user-complete:before {
  content: "\e905";
}
.icon-user:before {
  content: "\e906";
}
.icon-zoom:before {
  content: "\e907";
}
.icon-child:before {
  content: "\e908";
}
.icon-child-complete:before {
  content: "\e909";
}

.form-control {
  background-color: transparent;
  font-size: inherit;
  line-height: inherit;
  font-family: Roboto, system ui, sans-serif;
  color: #262626;
  border-radius: 6px;
}

