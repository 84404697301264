@font-face {
  font-family: 'icomoon';
  src:  url('./assets/fonts/icomoon.eot?6v4w6k');
  src:  url('./assets/fonts/icomoon.eot?6v4w6k#iefix') format('embedded-opentype'),
    url('./assets/fonts/icomoon.ttf?6v4w6k') format('truetype'),
    url('./assets/fonts/icomoon.woff?6v4w6k') format('woff'),
    url('./assets/fonts/icomoon.svg?6v4w6k#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right:before {
  content: "\e900";
}
.icon-arrow-up:before {
  content: "\e901";
}
.icon-check:before {
  content: "\e902";
}
.icon-calendar:before {
  content: "\e903";
}
.icon-edit:before {
  content: "\e904";
}
.icon-user-complete:before {
  content: "\e905";
}
.icon-user:before {
  content: "\e906";
}
.icon-zoom:before {
  content: "\e907";
}
.icon-child:before {
  content: "\e908";
}
.icon-child-complete:before {
  content: "\e909";
}
